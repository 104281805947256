

.user-details-layout
{
    background-color: transparent;

    .user-status
    {
        font-size: 16px;
        color: #313131;
        font-family: 'Poppins';
        font-weight: 600;
    }

    .user-status
    {
        font-family: 'Inter';

        .prior,
        .loan-status
        {
            text-align: center;
            padding: 10px;
            border-radius: 10px;
            background-color: #FFB367;
            color: #FFFFFF;
            margin-inline: 5px;
            margin-bottom: 5px;
        }

        .not-prior
        {
            text-align: center;
            padding: 10px;
            border-radius: 10px;
            background-color: #d82750;
            color: #FFFFFF;
            margin-inline: 5px;
            margin-bottom: 5px;
        }

        .loan-status
        {
            background-color: #282B7A;

            ul
            {
                padding: 0;
            }
        }
    }

    .user-details-area,
    .user-contact-area,
    .loan-details-area
    {
        border: 1px solid #313131;
        border-radius: 10px;
        margin: 10px;
        padding: 10px 10px;

        .section-heading
        {
            font-size: 16px;
            font-family: 'Inter';
            color: #313131;
            font-weight: 600;
        }

        .user-details-container
        {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding: 10px 10px;

            .details-label,
            .details-value
            {
                font-size: 14px;
                font-family: 'Inter';
                font-weight: 600;
            }

            .details-value
            {
                font-weight: normal;
            }
        }
    }

    .user-documents-area
    {
        border: 1px solid #313131;
        margin: 10px;
        padding: 10px 10px;
        border-radius: 10px;

        .section-heading
        {
            font-size: 16px;
            font-family: 'Inter';
            color: #313131;
            font-weight: 600;
            margin-bottom: 5px;
        }

        .document-contianer
        {
            background-color: #D8C9C9;
            border-radius: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 5px 5px;
            margin-bottom: 5px;

            .avatar-box
            {
                width: 80px;
                margin-inline: 10px;
                background-color: #D8C9C9;
                padding: 5px;
                border-radius: 5px;
                img
                {
                    width: 100%;
                }
            }

            .file-details
            {
                font-family: 'Inter';
                color: #595959;

                .file-name
                {
                    font-size: 12px;
                }

                .file-size
                {
                    font-size: 9px;
                }
            }

            .actions
            {
                position: absolute;
                right: 10px;

                .action-icons
                {
                    margin-inline: 5px;
                    color: #282B7A;
                    cursor: pointer;
                }
            }
        }
    }
}

.active-plan
{
    padding: 10px 10px;

    .section-heading
    {
        font-size: 16px;
        font-family: 'Inter';
        color: #313131;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .plan-card
    {
        background-color: #282b7add;
        position: relative;
        min-height: 210px;
        width:max-content;
        border-radius: 20px;

        img
        {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            border-radius: 20px;
        }

        .plan-details
        {
            position: relative;
            display: flex;
            justify-content: space-between;
            padding: 2rem 2rem;
            z-index: 1;

            .about-plan
            {
                font-family: 'Inter';
                color: #FFFFFF;

                .plan-name
                {
                    font-family: 'Poppins';
                    font-size: 22px;
                }

                .time-duration,
                .date-purchase,
                .date-expire
                {
                    font-size: 13px;
                }

                .date-purchase
                {
                    margin-top: 1rem;
                }
            }

            .plan-amount
            {
                font-size: 22px;
                font-family: 'Poppins';
                font-weight: 600;
                color: #FFFFFF;
                position: absolute;
                top: 1rem;
                right: 2rem;
            }
        }

                .no-active-plan {
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    padding: 2rem 2rem;
                    z-index: 1;
                    color: white;
                }
    }
}

