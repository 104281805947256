.aboutText {
  // height: 50vh;
  margin: 1rem 0.5rem;
}
.aboutStyle-Button {
  // margin-left: 980px;
}
.about-Button {
    width: 100px;
  }
  