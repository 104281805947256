.cms-main
{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.blog-content-area .ql-editor
{
  height: 220px;
  max-height: 220px;
}

.ant-upload
{
  width: 100%;
}

.ant-upload .ant-btn
{
  width: 100%;
  height: 42px;
}

.ant-upload-list-item-container .ant-upload-list-item
{
  height: 40px !important;
}