@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Poppins:wght@400;500;600&display=swap');

::-webkit-scrollbar 
{
    width: 8px;
}
   
::-webkit-scrollbar-track 
{
  background-color: #aaa;
}
   
::-webkit-scrollbar-thumb 
{
  background-color: #282b7a;
  border-radius: 8px;
}
  

body 
{
  background-color: #F9F9F9;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
}
  
// common styling

h2
{
    font-size: 24px;
    color: #313131;
    font-family: 'Poppins';
}

h3
{
    font-size: 18px;
    color: #313131;
    font-family: 'Poppins';    
}

ul
{
    list-style: none;
    margin: 0;
}

a
{
    text-decoration: none;
}

.btn-primary
{
    background-color: #F99734;
    border: 1px solid #F99734 !important;
    color: #FFFFFF;
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 600;
    padding: 10px !important;
    box-shadow: none !important;
}

.btn-primary:hover
{
    background-color: #F99734 !important;
    box-shadow: none;
    border: 1px solid #F99734 !important;
}


input[type='text'],
input[type='number'],
input[type='date'],
input[type='time'],
input[type='email'],
input[type='password'],
select.form-select
{
    color: #313131 !important;
    font-family: 'Inter' !important;
    font-size: 13px;
    line-height: 2.5;
    box-shadow: none !important;
    outline: none;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button 
{
  -webkit-appearance: none;
  margin: 0;
}

input:hover,
input:focus,
select:focus,
select:hover
{
    border: 1px solid #ced4da !important;
}

// common styling ends

.login-box
{
  background-color: #FFFFFF;
  margin:auto;
  margin-top: 5rem;
  border-radius: 10px;
  box-shadow: 0 0 20px rgb(0 0 0 / 16%);

  .illustration-wrapper
  {
    text-align: center;
    padding: 10px 20px;
    
    img
    {
      width: 175px;
    }
  }

  form
  {
    padding-inline: 20px;

    h3
    {
      font-size: 22px;
      margin-top: 5px;
      margin-bottom: 5px;
      text-align: center;
    }

    p
    {
      color: #282b7a;
      font-size: 13px;
      font-family: 'Inter';
      text-align: center;
    }

    .ant-form-item-margin-offset
    {
      display: none;
    }

    .ant-form-item
    {
      margin-bottom: 28px;
      .ant-form-item-control
      {
        margin-bottom: 10px;
        margin-top: 10px;

        .ant-form-item-explain-error
        {
          padding: 3px;
          padding-bottom: 5px;
          margin-top: 5px;
          font-size: 13px;
          font-family: 'Inter';
          font-weight: 500;
        }
      }
    }

    .form-submit
    {
      margin-bottom: 10px;

      .btn-primary
      {
        width: 100%;
        height: auto !important;
      }

    }

    .forgot-password
    {
      text-align: center;

      button
      {
        color: #313131;
        font-size: 13px;
        font-family: 'Inter';
        font-weight: 500;
      }

      button:hover
      {
        color: #282b7a;
      }
    }

    span.ant-input-password
    {
      box-shadow: none !important;
    }

    span.ant-input-password:hover,
    span.ant-input-password:focus
    {
      border: 1px solid #ced4da !important;
      box-shadow: none !important;
    }

    .anticon.ant-input-password-icon
    {
      color: #7B7B7B;
    }

    .ant-input-affix-wrapper.ant-input-password
    {
      input
      {
        border: 0 !important;
      }
    }

    .ant-input-affix-wrapper.ant-input-password
    {
      border: 1px solid #ced4da !important;
    }

    .ant-input-affix-wrapper.ant-input-password, .ant-input-affix-wrapper.ant-input-affix-wrapper-has-feedback
    {
      input
      {
        border: 0 !important;
      }
    }

    .ant-input-affix-wrapper.ant-input-password, .ant-input-affix-wrapper.ant-input-affix-wrapper-has-feedback
    {
      border: 1px solid #ced4da !important;
    }

    .ant-input-affix-wrapper.ant-input-password.ant-input-affix-wrapper-status-error,
    .ant-input-affix-wrapper.ant-input-affix-wrapper-status-error.ant-input-affix-wrapper-has-feedback
    {
      border-color: #ff4d4f !important;
    }


    .ant-input.ant-input-status-error
    {
      border-color: #ff4d4f !important;
    }

  }
}

@media screen and (min-width:992px)
{
  .ant-table-content::-webkit-scrollbar 
    { 
      height: 4px;
    }
}
/*new*/
@media screen and (max-width:992px)
{
  .ant-table-content::-webkit-scrollbar 
    { 
      display: none;
    }
}

  