@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Poppins:wght@400;500;600&display=swap');

body 
{
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code 
{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.main-container
{
  background-color: #F9F9F9;
  min-height: 100vh;
}

/* sidebar styling */


.logo
{
  height: 64px;
  padding: 10px;
  text-align: center;
}

.logo img
{
    width: 80%;
    height: 100%;
}

.ant-layout-sider-collapsed .logo
{
  display: none;
}

.ant-layout-sider
{
  background-color: #282B7A !important;
}

.ant-layout-sider .logo
{
  background-color: #D8C9C9;
}

.ant-layout-sider .ant-menu
{
  background-color: #282B7A;
}

.ant-layout-sider .ant-menu .ant-menu-sub,
.ant-menu.ant-menu-sub.ant-menu-vertical
{
  background-color: #282B7A !important; 
}

.ant-layout-sider .ant-menu .ant-menu-item
{
  font-family: 'Inter';
}

.ant-layout-sider .ant-menu .ant-menu-item.ant-menu-item-selected,
.ant-menu-item.ant-menu-item-active.ant-menu-item-selected,
.ant-menu-item.ant-menu-item-active.ant-menu-item-selected:focus,
.ant-layout-sider .ant-menu .ant-menu-item.ant-menu-item-active:focus,
.ant-menu-vertical .ant-menu-item.ant-menu-item-selected
{
  background-color: #0052ff !important;
}

.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-active .ant-menu-submenu-title:focus,
.ant-menu-submenu-vertical.ant-menu-submenu-active .ant-menu-submenu-title:focus
{
  background-color: transparent;
}


.ant-layout-sider .ant-layout-sider-trigger
{
  background-color: #D8C9C9;
  color: #313131;
}

/* sidebar styling ends */

.dashboard-header
{
  padding: 0 !important;
}

/* footer styling */

.footer
{
  text-align: center;
  font-family: 'Inter' !important;
  font-size: 13px !important;
  color: #313131 !important;
  padding: 15px 20px !important;
}

/* breadcrumbs styling */

.content-area
{
  padding: 0 15px;
}

.ant-breadcrumb
{
  padding: 15px 10px;
}

.ant-breadcrumb ol li
{
  font-family: 'Inter';
  font-size: 14px;
  color: #7B7B7B;
}

 /*Apply the styles for the table */
 table {
  @apply border-collapse border border-gray-300;
}

table tr:last-child {
  @apply border-b-0;
}

/* table th:first-child,
table td:first-child {
  @apply sticky-column;
} */

/* table td:first-child {
  @apply z-10 ;
} */

table th,
table td {
  @apply m-0 p-2 border-b border-r border-gray-300;
}
table th:last-child,
table td:last-child {
  @apply border-r-0;
}

table td input {
  @apply text-base p-0 m-0 border-0;
}
/* 
.sticky-column {
  position: sticky;
  left: 0;
} */

.tooltip-left {
  top: 50%;
  left: -100%; /* Position the tooltip to the left of the button */
  transform: translateY(-50%);
}

.tooltip-arrow[data-popper-arrow] {
  border-left-color: #333; /* Adjust the color as needed */
  left: 100%; /* Position the arrow on the left side of the tooltip */
  top: 50%;
  transform: translateY(-50%);
}