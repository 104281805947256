.ant-modal .ant-select-selection-placeholder
{
      line-height:3 !important
}

.ant-modal
{
  width: 750px !important;
}

.ant-modal-header .ant-modal-title
{
  font-family: 'Poppins';
  color: #313131;
  font-size: 18px;
  font-weight: 500;
}

.ant-modal .ant-space
{
  display: block;
}

.ant-modal .ant-form-item
{
  margin-bottom: 0;
}

.ant-modal .ant-select-selector
{
  height: 42px !important;
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
}

.ant-modal .ant-select-status-error .ant-select-selector
{
  border-color: #ff7875 !important;
}

.ant-modal .ant-select-selector .ant-select-selection-item
{
  line-height: 2.5;
}

.ant-modal .ant-picker
{
  width: 100%;
  height: 42px;
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
}

.ant-modal .ant-picker.ant-picker-status-error
{
  border-color: #0052ff !important;
}

.ant-modal .ant-picker .ant-picker-input input
{
  border: 0 !important;
}

.subscriptionBtn
{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.content-button-style
{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.add-button
{
  height: auto;
  font-weight: 500;
  margin-bottom: 8px;
}

.ql-editor
{
  max-height: 62vh;
  min-height: 30vh;
}

.cms-pagesbtn-container .btn-primary
{
  width: 100%;
  height: auto;
  margin-top: 10px;
}
.anticon.anticon-edit,
.anticon.anticon-delete,
.ant-dropdown-trigger
{
  color: #282b7a;
}

.ant-pagination-item.ant-pagination-item-active,
.ant-pagination-item.ant-pagination-item-active:hover
{
  border-color: #282b7a;
}

.ant-pagination-item.ant-pagination-item-active a,
.ant-pagination-item.ant-pagination-item-active:hover a
{
  color: #282b7a;
}

.ant-modal.ant-modal-confirm
{
  width: 430px !important;
}

.ant-modal.ant-modal-confirm .ant-modal-confirm-title
{
  font-family: 'Poppins';
  color: #313131;
}

.ant-modal.ant-modal-confirm .anticon.anticon-exclamation-circle
{
  color: #0052ff;
}

.ant-modal.ant-modal-confirm .ant-modal-confirm-btns
{
  text-align: center;
  font-family: 'Inter';
}

.ant-modal.ant-modal-confirm .ant-modal-confirm-btns .ant-btn-dangerous
{
  background-color: #ff7875 !important;
  color: #FFFFFF;
  border: 1px solid #ff7875 !important;
}

.ant-modal.ant-modal-confirm .ant-modal-confirm-btns .ant-btn-default
{
  background-color: #FFB367;
  color: #FFFFFF;
  box-shadow: none !important;
  border: 1px solid #FFB367;
}