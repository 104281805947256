.helpText {
    height: 50vh;
    margin: 1rem 0.5rem;
  }
  .helpStyle-Button {
    margin-left: 980px;
  }
  .help-Button {
      width: 100px;
    }
    body {
      background: #f3f1f2;
      font-family: sans-serif;
    }