.privacyText {
    margin: 1rem 0.5rem;
  }
  
  .privacy-Button {
    width: 100px;
  }

  .container {
    padding: 20px;
  }
  