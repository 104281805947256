.table {
  border: 1px solid #ddd;

  &.sticky {
    overflow: scroll;
    .header,
    .footer {
      position: sticky;
      z-index: 1;
      width: fit-content;
    }

    .header {
      top: 0;
      box-shadow: 0px 3px 3px #ccc;
    }

    .footer {
      bottom: 0;
      box-shadow: 0px -3px 3px #ccc;
    }

    .body {
      position: relative;
      z-index: 0;
    }

    [data-sticky-td] {
      position: sticky;
    }

    [data-sticky-last-left-td] {
      box-shadow: 2px 0px 3px #ccc;
    }

    [data-sticky-first-right-td] {
      box-shadow: -2px 0px 3px #ccc;
    }
  }
}