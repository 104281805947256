@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Poppins:wght@400;500;600&display=swap');

.dashboard-header
{
  background-color: #D8C9C9 !important;

  .afterloginheader
  {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;

    .innerHeader
    {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        padding-left: 10px;
        padding-right: 25px;
        position: relative;
        
        .details-area
        {
            display: flex;
            align-items: center;
            justify-content: space-around;

            .avatar-style
            {
                margin-inline: 10px;
                // display: inherit;
                order: 2;
                cursor: pointer;
            }

            .alertArea
            {
                position: relative;
                margin-inline: 20px;

                .anticon.anticon-bell
                {
                    font-size: 18px;
                    color: #313131;
                    cursor: pointer;
                }

                .ant-badge
                {
                    position: absolute;
                    right: -1.5rem;
                    top: 1rem;
                }
            }
        }

        .moreOptions
        {
            
            position: absolute;
            top: 4.1rem;
            right: 1rem;
            z-index: 1;
            background: #d8c9c9;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            // display: none;
            
            ul
            {
                padding: 0;

                li
                {
                    color: #313131;
                    font-family: 'Inter';
                    font-size: 15px;
                    padding: 5px 20px;
                    line-height: 2;
                    border-bottom: 1px solid #282B7A;
                    cursor: pointer;
                }

                li:hover:not(.userName)
                {
                    background-color: #FFB367;
                    border-color: #FFB367; 
                }

                li:last-child
                {
                    border-bottom: 0;
                }

                li.userName
                {
                    color: #282B7A;
                    font-family: 'Poppins';
                    font-weight: 600;
                    text-transform: capitalize;
                    text-align: center;
                    padding-bottom: 0px;
                    cursor: auto;
                }
            }
        }

        .notificationContainer
        {
            width: 250px;
            display: block;
            position: absolute;
            top: 4.1rem;
            right: 1rem;
            z-index: 1;
            background: #d8c9c9;
            // display: none;

            ul
            {
                padding: 0;
                max-height: 350px;
                overflow: auto;

                li
                {
                    color: #313131;
                    font-family: 'Inter';
                    font-size: 15px;
                    padding: 5px 20px;
                    line-height: 2;
                    border-bottom: 1px solid #282B7A;
                }

                li:last-child
                {
                    border-bottom: 0;
                }
            }

            .btn-primary
            {
                position: absolute;
                width: 100%;
                text-transform: capitalize;
                cursor: pointer;
            }
        }
    }
  }
}

@media screen and (max-width: 250px)
{
    .moreOptions
    {
        ul
        {
            li
            {
                font-size: 13px !important;
            }
        }
    }   
}